.rmsc .dropdown-container {
  border-radius: 0px !important;
  border: none !important;
  border-bottom: 1px solid !important;
}
.rmsc .dropdown-container:focus-within {
  box-shadow: none !important;
  border: 1px solid #ccc !important;
  z-index: 999;
}
.rmsc .dropdown-heading-value {
  text-align: start !important;
}
.logCheckBox[type="checkbox"] {
  cursor: pointer;
}

#logTable {
  overflow-x: scroll;
}
.ages {
  color: black;
}

.child-ages-list-modal {
  width: 100%;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 99;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  border-radius: 2px;
  padding: 1rem;
  display: none;
}

.child-ages-list-modal-item {
  width: 100%;
  border-radius: 2px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}
.child-ages-list-modal-item-buton {
  outline: none;
  border: none;
  border-radius: 3px;
  background-color: #33658a;
  padding: 0.3rem;
  cursor: pointer;
  color: white;
  width: 5rem;
}

.child-ages-list-modal-item-buton:active {
  border: 1px solid black;
  background-color: #547894;
}

.child-ages-list-modal-item-input {
  outline: none;
  border: none;
  box-shadow: 0 0 2px black;
  border-radius: 2px;
  color: black;
  width: 7rem;
  text-align: center;
  padding: 0.15rem;
}
.child-ages-modal-template {
  max-height: 20rem;
  overflow-y: scroll;
}
.child-ages-modal-item-text {
  width: 100%;
  text-align: center;
  letter-spacing: 0.2rem;
  height: 1.2rem;
  padding: 1rem;
  border: 0.1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 3px;
  background-color: white !important;
}

/* company first page company accourdion style */
.accourdionOpentoTop {
  position: absolute !important;
  width: -webkit-fill-available !important;
  z-index: 9999 !important;
}
/* .visibleOverflow {
  overflow-y: visible !important;
 
  
}
 .visibleOverflow .MuiCollapse-wrapperInner .MuiPaper-root > div:nth-of-type(2) {
  overflow-x: auto !important; 
   overflow-y: visible !important; 

} 

 .visibleOverflow .MuiCollapse-wrapperInner .MuiPaper-root > div:nth-of-type(2) >div > div {
  overflow-x: scroll !important;
  overflow-y: visible !important;

}  */
